import { createApp } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faCircleNotch,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSpinner, faCircleNotch, faRotateRight);

import App from './App.vue';

createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
