import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { Ref } from 'vue';

export class Streamer {
  playerId: string;
  videoElm: HTMLVideoElement;
  streamKey: string | undefined;
  headers: { accept: string; 'content-type': string };
  antBaseUrl: string;
  webRTCAdaptor!: WebRTCAdaptor;

  constructor(videoElement: HTMLVideoElement, streamKey: string | null = null) {
    this.playerId = videoElement.id;
    this.videoElm = videoElement;
    this.streamKey = streamKey;
    this.headers = {
      accept: 'application/json',
      'content-type': 'application/json',
    };
    this.antBaseUrl = 'antmedia.horse24.com';
  }

  async checkAntMediaHasStream(): Promise<boolean> {
    const url = `https://${this.antBaseUrl}/LiveApp/rest/v2/broadcasts/${this.streamKey}`;

    try {
      const result = await fetch(url, {
        headers: this.headers,
      });
      const json = await result.json();
      return ['broadcasting'].includes(json.status);
    } catch (error) {
      return false;
    }
  }

  playAntMediaStream(hasStartedRef: Ref<boolean>): void {
    const websocketURL = `wss://${this.antBaseUrl}:5443/LiveApp/websocket`;
    const webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: websocketURL,
      mediaConstraints: {
        video: false,
        audio: false,
      },
      peerconnection_config: {
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
      remoteVideoId: this.playerId, // <video id="id-of-video-element" autoplay muted></video>
      // bandwidth: int|string, // default is 900 kbps, string can be 'unlimited'
      dataChannelEnabled: false, // enable or disable data channel
      isPlayMode: true,
      debug: false,
      candidateTypes: ['tcp', 'udp'],
      callback: (info: string, obj: any) => {
        if (info == 'play_started') {
          //joined the stream
          console.log('play started');
          webRTCAdaptor.getStreamInfo(this.streamKey);
          webRTCAdaptor.enableStats(obj.streamId);
          hasStartedRef.value = true;
        } else if (info === 'initialized') {
          console.log('Player initialized');
          webRTCAdaptor.play(this.streamKey);
        } else if (info == 'play_finished') {
          console.log('Stream stopped');
          webRTCAdaptor.stop(this.streamKey);
          hasStartedRef.value = false;
        }
      },
      callbackError: function (error: any, message: any) {
        console.log('AntMedia error: ', { error, message });
      },
    });

    this.webRTCAdaptor = webRTCAdaptor;
  }

  switchStream(streamKey: string, hasStartedRef: Ref<boolean>) {
    // Check if connected to the current stream.
    // This is just a placeholder; replace with the actual method from WebRTCAdaptor.
    if (this.webRTCAdaptor) {
      this.webRTCAdaptor.leave(this.streamKey);
    }
    this.streamKey = streamKey;
    hasStartedRef.value = false;
    this.playAntMediaStream(hasStartedRef);
  }
}
