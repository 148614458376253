<template>
  <div></div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from 'vue';
import Swal from 'sweetalert2';

const hostname = window.location.hostname;
const redirectBase = `https://${hostname}`;

const siteIsUp = ref(false);
const props = defineProps({
  lang: {
    type: String,
    required: true
  }
})

const lang = computed(() => props.lang);

async function checkSiteUp() {
  try {
    const headers = {
      accept: 'text/html',
      'content-type': 'text/html',
    };

    if (hostname.includes('dressage24.com')) {
      headers['Authorization'] = `Basic ${btoa('h24:secur*e')}`;
    }

    const resp = await fetch(`${redirectBase}/healthz`, {
      headers,
    });

    if (resp.ok) {
      const content = await resp.text();
      console.log(content);

      if (content === 'ok') {
        siteIsUp.value = true;

        const titleText =
          lang.value === 'en' ? 'Good news!' : 'Gute Nachrichten!';
        const confirmButtonText = lang.value === 'en' ? 'Redirect' : 'Umleiten';
        const text =
          lang.value === 'en'
            ? `The online auction platform is up now! Please click the button below to be redirected.`
            : `Die Online-Auktionsplattform ist jetzt online! Bitte klicken Sie auf die Schaltfläche unten, um weitergeleitet zu werden.`;

        Swal.fire({
          titleText,
          text,
          confirmButtonText,
        }).then(resp => {
          if (resp.isConfirmed) {
            document.location.href = redirectBase;
          }
        });
      }
    } else {
      setTimeout(checkSiteUp, 5000);
    }
  } catch (error) {
    setTimeout(checkSiteUp, 5000);
  }
}

// On component mounted
onMounted(() => {
  checkSiteUp();
});
</script>